import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container, ListGroup, Carousel } from "react-bootstrap"
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Row className="unhistoried-index-slideshow-row">
      <Col md={2}></Col>
      <Col md={8} className="unhistoried-index-slideshow-col">
        <h1>404 NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Col>
      <Col md={2}></Col>
    </Row>
  </Layout>
)

export default NotFoundPage
